import { gql } from '@apollo/client';

export const getCmsPage = gql`
    query($identifier: String!) {
        cmsPage(identifier: $identifier) {
            identifier
            content
            meta_description
            meta_keywords
            meta_title
            title
            url_key
        }
    }
`;

export const getInstagramToken = gql`
{
    instagramToken {
      token
    }
}
`;

export const getPageBuilderTemplate = gql`
query($identifier: String!){
    getPageBuilderTemplate(id: $identifier){
        data
    }
}
`;

export const getInstagramFeed = gql`
mutation getInstagramFeed($token: String!) {
    internalGetInstagramFeed(token: $token) {
        message
        data {
            id
            media_type
            media_url
            permalink
            caption
            username
        }
        err
    }
}
`;

export const getCmsBlocks = gql`
    query($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getProductReviews = gql`
    query getProductReviews($sku: String, $pageSize: Int) {
        products(
            filter: { 
                sku: {
                    eq: $sku
                }
            }, 
            pageSize: $pageSize
        ) {
            items {
                id
                sku
                name
                url_key
                small_image {
                    url
                    label
                }
                reviews {
                    items {
                        nickname
                        summary
                        created_at
                        text
                        ratings_breakdown {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const getProductPrice = gql`
    query getProductPrice($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                id
                name
                sku
                url_key
                price_daily_deal {
                    currency
                    value
                }
                price_range {
                    maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
                price_tiers {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    quantity
                }
            }
        }
    }`;

export const getProductList = gql`
    query getProductList($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                seller {
                    seller_name
                }
                id
                name
                sku
                url_key
                review_count
                amasty_product_labels {
                    label_id
                    label_name
                    label_text
                    image
                    redirect_url
                    image_size
                    position
                    style
                    background_color
                    popup_block
                }
                short_description {
                    html
                }
                price_daily_deal {
                    currency
                    value
                }
                price_range {
                    maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
                price_tiers {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    quantity
                }
                small_image {
                    label
                    url
                }
                review {
                    rating_summary
                    reviews_count
                }
                new_from_date
                new_to_date
                stock_status
                weltpixel_labels {
                    productLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_bg_color
                        text_font_color
                        text_font_size
                        text_padding
                    }
                    categoryLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_bg_color
                        text_font_color
                        text_font_size
                        text_padding
                      }
                }
                
            }
        }
    }
`;

export const getCategories = gql`
    query getCategories($ids: [String]) {
        categoryList(filters: { ids: { in: $ids } }) {
            name
            product_count
            url_path
            children {
                name
                product_count
                url_path
                children {
                    name
                    product_count
                    url_path
                    children {
                        name
                        product_count
                        url_path
                        children {
                            name
                            product_count
                            url_path
                        }
                    }
                }
            }
        }
    }
`;

export const getCmsWidget = gql`
    query($id: Int, $title: String, $productId: [Int], $categoryId: [Int]) {
        cmsWidget(id: $id, title: $title, productId: $productId, categoryId: $categoryId) {
            data {
            id
            layout {
                entities
                block_reference
                page_for
                page_group
            }
            sort_order
            store_ids {
                code
            }
            theme
            title
            type
            widget_options
            }
        }
    }
`;

export default { getCmsPage };
